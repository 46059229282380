exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2LUgwjcH9-ipGtK-desv4A{opacity:.3;background-color:#9e1f63;width:40px;height:40px;position:fixed;bottom:60px;right:20px;border-radius:5px;border:none}._2LUgwjcH9-ipGtK-desv4A.middle{right:35%}._2LUgwjcH9-ipGtK-desv4A :hover{opacity:1}.KH_DOVQcLpGxLs5KBzq_p{color:#fff;position:absolute;top:50%;left:50%;margin-top:-9px;margin-left:-5px}", ""]);

// exports
exports.locals = {
	"scroll": "_2LUgwjcH9-ipGtK-desv4A",
	"arrowUp": "KH_DOVQcLpGxLs5KBzq_p"
};