exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-HNR5l41jTfXEGyWmDcIN.alert.alert-info{box-shadow:0 2px 10px rgba(0,0,0,.2);position:fixed;top:60px;left:50%;height:32px;width:200px;margin-left:-100px;padding:5px 10px;text-align:center;z-index:2000}", ""]);

// exports
exports.locals = {
	"loadingIndicator": "-HNR5l41jTfXEGyWmDcIN"
};