exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2NwaFVSf80fmZscksRCYco{padding-left:0;padding-right:0}._2NwaFVSf80fmZscksRCYco .popover-content{min-width:200px;padding:0}._2NwaFVSf80fmZscksRCYco .list-group{margin-bottom:0}._2NwaFVSf80fmZscksRCYco .list-group-item{border-right:0;border-left:0;padding:6px 15px}._2NwaFVSf80fmZscksRCYco .list-group-item:first-child{border-top-right-radius:0;border-top-left-radius:0}._2NwaFVSf80fmZscksRCYco .list-group-item:last-child{border-bottom:0}._1U3FdrQdZY4GXU7aaRDwq0{max-height:340px;overflow:auto}.fJLXbiFrKPQcmZBohb5LI{margin-bottom:0!important;padding:5px}", ""]);

// exports
exports.locals = {
	"customPopover": "_2NwaFVSf80fmZscksRCYco",
	"scrollableList": "_1U3FdrQdZY4GXU7aaRDwq0",
	"dataFilterInput": "fJLXbiFrKPQcmZBohb5LI"
};