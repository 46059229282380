exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26CnxIiLVQOPzfY4a1LSTK{padding-left:0;margin-bottom:20px}._26CnxIiLVQOPzfY4a1LSTK ._26CnxIiLVQOPzfY4a1LSTK{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"list": "_26CnxIiLVQOPzfY4a1LSTK"
};