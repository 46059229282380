exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3lRnjxPk00oPpbW9exejz1{text-align:center;font-size:1.1em}._3lRnjxPk00oPpbW9exejz1 h3{margin-top:5px;margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"component": "_3lRnjxPk00oPpbW9exejz1"
};