exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2uem0hM456tRfmPVM2Gkai .popover-content{padding:0}", ""]);

// exports
exports.locals = {
	"customPopover": "_2uem0hM456tRfmPVM2Gkai"
};