exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1GCj-6M_88kLPO31i2xw2T{border-right:1px solid #d1d1d1}._117zizv9MpryLKv-gI1OKe{margin-bottom:15px}._3xhJiaYDdWgP5WWZB2MU0F{padding:7px}", ""]);

// exports
exports.locals = {
	"subnavigation": "_1GCj-6M_88kLPO31i2xw2T",
	"horizontal": "_117zizv9MpryLKv-gI1OKe",
	"horizontalPreviousNextButtons": "_3xhJiaYDdWgP5WWZB2MU0F"
};